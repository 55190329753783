import {AddWorkspaceInviteesCardHintProps} from './types';
import {useTranslation} from 'react-i18next';

import {isEmailValid, sanitizeEmail} from '@lib/utils';

import {config, useTransition} from 'react-spring';

import {P} from '@quarks';
import {StyledAnimatedDiv, StyledWarning} from './styles';
import {MAX_INVITEE_COUNT} from '@constants';
import {useAppSelector} from '@hooks';
import {getSettings} from '@lib/store';

export const AddWorkspaceInviteesCardHint = ({
  debouncedSearchTerm,
  isSearching,
  length,
  searchTerm,
  reachedMaxSelection,
}: AddWorkspaceInviteesCardHintProps) => {
  const {t} = useTranslation();
  const show = searchTerm.length === 0 || debouncedSearchTerm.length >= 1;
  const transition = useTransition(show, {
    initial: {opacity: 1, transform: 'translate3d(0, 0px, 0) scale(1)'},
    from: {opacity: 0, transform: 'translate3d(0, 10px, 0) scale(1)'},
    enter: {opacity: 1, transform: 'translate3d(0, 0px, 0) scale(1)'},
    leave: {opacity: 0, transform: 'translate3d(0, 10px, 0) scale(1)'},
    config: config.stiff,
  });
  const {invitationExpireDays} = useAppSelector(getSettings);

  const sanitizedEmail = sanitizeEmail(debouncedSearchTerm);
  const validEmail = isEmailValid(sanitizedEmail);

  return transition(
    (style, item) =>
      item && (
        <StyledAnimatedDiv style={{...style}}>
          {!isSearching && !length ? (
            <>
              <P fontWeight="bold">{searchTerm.length === 1 ? t('connection:NoUsersFound') : null}</P>
              {reachedMaxSelection ? (
                <StyledWarning>
                  {t('workplace:InvalidNumberOfInviteesMessage', {number: MAX_INVITEE_COUNT})}
                </StyledWarning>
              ) : null}
              <P>
                {!searchTerm.length
                  ? t('workplace:InviteesSearchInitalMessage', {number: invitationExpireDays})
                  : searchTerm.length === 1
                  ? t('connection:TypeMoreCharsToFindColleagues')
                  : searchTerm.length >= 2 && validEmail
                  ? t('meeting:CreateMeetingInviteeEmptyMessage')
                  : t('workplace:InviteesNoUserFound')}
              </P>
            </>
          ) : null}
        </StyledAnimatedDiv>
      ),
  );
};
