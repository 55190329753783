import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {AddInviteesCardProps} from './types';
import {useTranslation} from 'react-i18next';
import {TransitionGroup} from 'react-transition-group';
import uniqBy from 'lodash/uniqBy';

import {
  MeetingInviteeSearchResult,
  searchGroupWorkspaceInvitees,
  searchMultipleGroupWorkspaceInvitees,
  withAsyncThunkErrorHandling,
} from '@lib/store';
import {findByEmail, useDebounce} from '@lib/utils';

import {useAppDispatch, useModal, useScrollParentForChild} from '@hooks';

import {ChipSelect, InviteeCardCell} from '@molecules';
import {AddWorkspaceInviteesCardHint, ModalPage, ModalPageBottom} from '@organisms';
import {Div, FlexCol, Grid} from '@quarks';
import {MAX_INVITEE_COUNT} from '@constants';

export const AddWorkspaceInviteesCard = ({onClose, onSave, passedInvitees}: AddInviteesCardProps) => {
  const {t} = useTranslation();
  const {removePages} = useModal();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const [isSearching, setIsSearching] = useState(false);
  const [resultsArray, setResultsArray] = useState<MeetingInviteeSearchResult[]>([]);
  const [selectedInvitee, setSelectedInvitee] = useState<number | undefined>(undefined);
  const [invitees, setInvitees] = useState<MeetingInviteeSearchResult[]>(passedInvitees);
  const dispatch = useAppDispatch();
  const [scrollRef, childWrapperRef] = useScrollParentForChild(selectedInvitee);

  const reachedMaxSelection = invitees.length >= MAX_INVITEE_COUNT;

  const handleMultiSearch = useCallback(
    async (query: string[]) => {
      const result = await dispatch(withAsyncThunkErrorHandling(() => searchMultipleGroupWorkspaceInvitees(query)));
      const payload = result.result as MeetingInviteeSearchResult[];

      setInvitees((prevInvitees) => {
        const uniqueInvitees = uniqBy([...prevInvitees, ...payload], 'id');

        return uniqueInvitees.splice(0, MAX_INVITEE_COUNT);
      });

      setSearchTerm('');
    },
    [dispatch],
  );

  const handleSingleSearch = useCallback(
    async (query: string) => {
      const result = await dispatch(withAsyncThunkErrorHandling(() => searchGroupWorkspaceInvitees(query)));

      const payload = result.result as MeetingInviteeSearchResult[];
      setResultsArray(payload);
    },
    [dispatch],
  );

  useEffect(() => {
    const handleSearch = async () => {
      const splitRegex = /[,|;|\|]\s{0,}/; // split on , ; |
      const queryStrings = debouncedSearchTerm.split(splitRegex).filter(Boolean);
      const isMultiSearch = queryStrings.length > 1;

      if (isMultiSearch) {
        await handleMultiSearch(queryStrings);
      } else {
        await handleSingleSearch(queryStrings[0]);
      }

      setIsSearching(false);
    };

    if (debouncedSearchTerm.length >= 2) handleSearch();
    if (debouncedSearchTerm.length < 2) {
      setResultsArray([]);
      setIsSearching(false);
    }
  }, [debouncedSearchTerm, handleSingleSearch, handleMultiSearch]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length >= 2) {
      setResultsArray([]);
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    setSearchTerm(e.target.value);
  };

  const filteredResultsArray =
    resultsArray.length > 0 ? resultsArray.filter((result) => !findByEmail(invitees, result.email)) : [];

  const handleOnClickInvitee = (user: MeetingInviteeSearchResult) => {
    setInvitees((prevInitees) => [...prevInitees, user]);
    setSearchTerm('');
    setResultsArray([]);
  };

  return (
    <ModalPage
      bottom={
        <ModalPageBottom
          buttonLabel={t('translation:Confirm')}
          onClick={() => {
            onSave(invitees);
            removePages(1);
          }}
        />
      }
      onBack={() => removePages(1)}
      onClose={onClose}
      ref={scrollRef}
      title={t('screen:Invitees')}>
      <FlexCol
        gap={16}
        height="100%">
        <ChipSelect
          isOutsideInviteesAllowed={false}
          reachedMaxSelection={reachedMaxSelection}
          isLoading={isSearching}
          onChange={handleChange}
          optionsArray={filteredResultsArray}
          placeholder={t('translation:SearchInviteePlaceholder')}
          isPlaceholderTransparent={!!invitees.length}
          selectedOptionIndex={selectedInvitee}
          selection={invitees}
          setSelectedOptionIndex={setSelectedInvitee}
          setSelection={setInvitees}
          setValue={setSearchTerm}
          value={searchTerm}
        />

        <hr />

        <Grid
          alignItems="flex-start"
          gridTemplateColumns="[full-start] 100% [full-end]"
          gridTemplateRows="[full-start] 100% [full-end]"
          height="100%">
          <Div gridArea="full">
            <AddWorkspaceInviteesCardHint
              reachedMaxSelection={reachedMaxSelection}
              debouncedSearchTerm={debouncedSearchTerm}
              isSearching={isSearching}
              length={resultsArray.length}
              searchTerm={searchTerm}
            />
          </Div>
          <FlexCol
            columnGap={8}
            gridArea="full">
            <div ref={childWrapperRef}>
              <TransitionGroup component={null}>
                {filteredResultsArray.map((user, i) => (
                  <InviteeCardCell
                    key={user.id}
                    i={i}
                    length={filteredResultsArray.length}
                    onClick={() => handleOnClickInvitee(user)}
                    selected={selectedInvitee === i}
                    user={user}
                  />
                ))}
              </TransitionGroup>
            </div>
          </FlexCol>
        </Grid>
      </FlexCol>
    </ModalPage>
  );
};
